import * as React from "react";
import { useState, useEffect } from "react";

import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout.js";

import SEO from "../components/seo.js";
import "@fontsource/Oswald/400.css";
import "@fontsource/Oswald/600.css";
import "@fontsource/source-sans-pro/300.css";
import "@fontsource/source-sans-pro/900.css";

const IndexPage = ({ data }) => {
  const imageData = data.desktop.childImageSharp.fluid;

  const [vis, setVis] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVis(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []); // empty array tells React to cleanup and never run again

  return (
    <Layout>
      <SEO title={""} />
      <main className="about">
        <BackgroundImage
          Tag="div"
          className="map"
          fluid={imageData}
          backgroundColor={`#1c1c1c`}
        >
          <div className="text-column-wrapper">
            <figure className={`quote ${vis ? "visible" : ""}`}>
              <StaticImage
                className="logo"
                src="../images/newsobserver.png"
                alt="News &amp; Observer"
                placeholder="blurred"
                layout="constrained"
                width={400}
              />
              <blockquote>&quot;Through his eyes, the street most would rank among Raleigh's ugliest takes on an insistent quality, demanding not only to be noticed, but exaulted for its too-human defects.&quot;</blockquote>
              <figcaption>
                &ndash;Josh Shaffer,
                <cite>
                  <Link
                    to="https://www.newsobserver.com/news/local/article269477192.html"
                    target="_blank"
                  >
                    The Raleigh News & Observer
                  </Link>
                </cite>
              </figcaption>
            </figure>
            <h1>
              One Street <br className="mobile-only" />
              in Raleigh
            </h1>
            <h2>
              My search for everyday beauty
              <br /> on Capital Boulevard
            </h2>
            <div className={`text-column ${vis ? "visible" : ""}`}>
              <p>The One Street City project started by accident. I woke up early one morning a couple of years ago and I was having a hard time getting back to sleep. So I decided to go for a walk, taking my camera with me.</p>
              <p>Before long I found myself near a big street called Capital Boulevard. It was uncharacteristically quiet. Raleigh people know Capital as a busy street with lots of billboards, traffic, car lots, and chain restaurants. It’s not a place that’s known for its scenery. That morning though, it was peaceful. And with the sun coming up, it had a certain kind of beauty.</p>
              <p>Seeing Capital Boulevard this way, it felt new. Like unexplored territory. For a photographer, this was fertile ground. Here was a complex landscape that changes by the minute and I was here to capture it. So I started One Street to share what I found.</p>
              <p>My sincere hope is that my photos will inspire you to appreciate the mundane parts of life&mdash;The glow of a McDonald’s drive-thru at night, how a crack in a telephone pole can tell a story, the way six lanes of traffic can transform into a kaleidoscope of color, texture, and light. You only have to choose to see it that way.</p>
              <h3>Behind One Street City</h3>
              <figure className="profile-photo">
                <StaticImage
                  src="../images/ben-harris.jpg"
                  alt="Ben Harris"
                  placeholder="blurred"
                  layout="constrained"
                  width={1000}
                />
                <figcaption>
                  <Link
                    to="https://alius.design/"
                    target="_blank"
                  >
                    Photo by Aluis
                  </Link>
                </figcaption>
              </figure>
              <p>
                The photographer behind One Street City is me, Ben Harris. I shoot mostly with FujiFilm X-system cameras, occasionally taking one of my film cameras with me. When I’m not working on One Street, I’m running a small full-service design shop in Raleigh called <Link to="https://tiptopstudio.com">Tip Top Studio</Link>.
              </p>
              <p>
                Want to say hi, or collaborate on a project? <Link to="/contact">Send me an email</Link>. I love talking about this stuff.
              </p>
            </div>
          </div>
        </BackgroundImage>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    desktop: file(name: { eq: "map-transparent" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
